<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.clients.title') }}</h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <b-button v-if="$store.getters.isMeRole(['superadmin'])" variant="primary" class="ml-2" @click="$refs.modalClient.buttonAddClient">
                        <i class="fa fa-plus mr-2" />
                        {{ $t('pages.clients.button-add_client') }}
                    </b-button>
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-pagination
                v-if="clients.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredClients.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredClients" :fields="fields">
                <template v-slot:head(clientName)>
                    {{ $t('pages.clients.th-client_name') }}
                </template>
                <template v-slot:cell(clientName)="c">
                    <i class="fa fa fa-building-o mr-2" />
                    <i v-if="c.item.disabledAt" class="fa fa-ban mr-2 text-danger" />
                    <router-link :to="`/superadmin/client/${c.item.clientID}/projects`">
                        {{ c.item.clientName }}
                        <!--<i class="fa fa-arrow-circle-o-right ml-2" />-->
                    </router-link>
                </template>

                <template v-slot:head(plan)>
                    {{ $t('pages.clients.th-plan') }}
                </template>
                <template v-slot:cell(plan)="c">
                    <div>{{ _.has(c.item, 'meta.plan') ? $t(`pages.pricing.plan-${c.item.meta.plan}`) : '-' }}</div>
                    <div class="small">
                        <div v-if="_.has(c.item, 'meta.maxUsers')">{{ $t('pages.payment.label-max_users') }} {{ c.item.meta.maxUsers }}</div>
                        <div v-if="_.has(c.item, 'meta.maxProjects')">{{ $t('pages.payment.label-max_projects') }} {{ c.item.meta.maxProjects }}</div>
                        <div v-if="_.has(c.item, 'meta.maxSites')">{{ $t('pages.payment.label-max_sites') }} {{ c.item.meta.maxSites }}</div>
                    </div>
                </template>

                <template v-slot:head(validUntil)>
                    {{ $t('pages.clients.th-valid_until') }}
                </template>
                <template v-slot:cell(validUntil)="c">
                    <div v-if="_.get(c.item, 'meta.validUntil')" :class="{ 'text-danger': isDateExpired(c.item.meta.validUntil) }">
                        <i v-if="isDateExpired(c.item.meta.validUntil)" class="fa fa-exclamation" />
                        {{ getDateFormat(c.item.meta.validUntil) }}
                    </div>
                    <div v-else>-</div>
                    <!-- <div v-if="_.has(c.item, 'meta.duration')" class="small">{{ $t(`pages.pricing.duration-${c.item.meta.duration}`) }}</div> -->
                </template>

                <template v-slot:head(status)>
                    <div class="text-right">
                        {{ $t('pages.clients.th-status') }}
                    </div>
                </template>
                <template v-slot:cell(status)="c">
                    <div class="text-right">
                        <div v-if="c.item._statuses" class="small">
                            <div v-if="c.item._statuses.projects">Projects: {{ c.item._statuses.projects }}</div>
                            <div v-if="c.item._statuses.ready">Ready: {{ c.item._statuses.ready }}</div>
                            <div v-if="c.item._statuses.wait">Wait: {{ c.item._statuses.wait }}</div>
                            <div v-if="c.item._statuses.error">Error: {{ c.item._statuses.error }}</div>
                        </div>
                        <div v-if="_.get(c.item._statuses, 'processing')">
                            <b-progress height="5px" class="mt-2 progress-begin" variant="secondary" :value="100" :max="100" animated />
                        </div>
                    </div>
                </template>

                <template v-slot:head(actions)>
                    <div class="text-right">
                        {{ $t('pages.clients.th-actions') }}
                    </div>
                </template>
                <template v-slot:cell(actions)="c">
                    <div class="text-right">
                        <b-button class="ml-1" variant="text" @click="$refs.modalClient.buttonEditClient(c.item, $event.target)"><i class="fa fa-edit" /></b-button>
                        <b-button class="ml-1" variant="text" @click="$refs.modalClient.buttonDeleteClient(c.item, $event.target)"><i class="fa fa-trash" /></b-button>
                        <b-button class="ml-1" variant="text" :disabled="c.item._processing" @click="$refs.modalClient.buttonDisableClient(c.item, $event.target)">
                            <i v-if="c.item.disabledAt" class="fa fa-minus-square text-danger" />
                            <i v-if="!c.item.disabledAt" class="fa fa-check-square" />
                        </b-button>
                    </div>
                </template>
            </b-table>

            <b-pagination
                v-if="clients.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredClients.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <ModalClient ref="modalClient" @editedClient="onEditedClient" @addedClient="onAddedClient" @deletedClient="onDeletedClient" />
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import t from '@/tool';

import ModalClient from '@/components/modalClient.vue';

export default {
    components: {
        ModalClient,
    },
    data() {
        return {
            loading: false,
            statusing: false,
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                // { key: 'clientID', label: 'ID', sortable: true, sortDirection: 'asc', thStyle: 'width: 6rem' },
                { key: 'clientName', sortable: true, sortDirection: 'asc' },
                { key: 'plan', thStyle: 'width: 10rem' },
                { key: 'validUntil', sortable: true, sortDirection: 'asc', thStyle: 'width: 10rem' },
                { key: 'status', thStyle: 'width: 10rem' },
                { key: 'actions', thStyle: 'width: 9rem' },
            ],
            clients: [],
            statuses: {},
            timeInterval: 30,
        };
    },
    computed: {
        filteredClients() {
            const search = t.replaceSearch(this.search);
            return this.clients.filter((c) => `${c.clientName || ''}`.match(search));
        },
    },
    async created() {
        this.loading = true;
        try {
            const response = await axios.get(`/api/superadmin/clients`);
            this.clients = [...response.data.clients];
            this.progressStatus();
        } catch (error) {
            console.log('catch', error);
        }
        this.loading = false;

        this.timeout = setInterval(() => {
            if (--this.timeInterval === 0) {
                this.progressStatus();
            }
        }, 500);
    },
    destroyed() {
        if (this.timeout) {
            clearInterval(this.timeout);
            this.timeout = null;
        }
    },
    methods: {
        getDateFormat: (date) => moment(date).format('YYYY.MM.DD HH:mm'),
        isDateExpired: (date) => +moment(date) < +moment(),

        async progressStatus() {
            if (!this.timeout) {
                return;
            }
            if (this.statusing) {
                this.timeInterval = 5;
                return;
            }
            this.statusing = true;
            try {
                const resp = await axios.post(`/api/superadmin/clients/statuses`);

                this.timeInterval = 30;
                const statuses = _.get(resp, 'data.statuses', {});

                _.each(statuses, (c) => {
                    _.each(c, (p) => {
                        p.count = _.get(p, 'count', {});
                        _.unset(p.count, 'deleted');
                        p.ready = _.get(p.count, 'ready', 0);
                        p.wait = _.max(_.get(p.count, 'wait', 0), 0);
                        p.max = 0;
                        p.error = 0;
                        _.each(p.count, (s, status) => {
                            p.max += s;
                            if (_.indexOf(['ready', 'wait', 'limit', 'stop', 'deleted'], status) === -1) {
                                p.error += s;
                            }
                        });
                        p.value = p.ready;
                    });
                    c.projects = _.size(c);
                    c.ready = _.sumBy(_.values(c), 'ready') || 0;
                    c.wait = _.sumBy(_.values(c), 'wait') || 0;
                    c.processing = !!_.find(c, { processing: true });
                });
                _.each(this.clients, (c) => {
                    if (statuses[c.clientID]) {
                        this.$set(c, '_statuses', statuses[c.clientID]);
                        if (statuses[c.clientID].processing) {
                            this.timeInterval = 5;
                        }
                    } else {
                        this.$delete(c, '_statuses');
                    }
                });
            } catch (error) {
                console.log('catch', error);

                this.timeInterval = 5;
            }
            this.statusing = false;
        },

        onAddedClient(client) {
            this.clients.push({ ...client });
        },

        onEditedClient(client) {
            const index = _.findIndex(this.clients, { clientID: client.clientID });
            this.$set(this.clients, index, { ...this.clients[index], ...client });
        },

        onDeletedClient(client) {
            this.$delete(this.clients, _.findIndex(this.clients, { clientID: client.clientID }));
        },
    },
};
</script>
