<template>
    <div>
        <b-modal id="modal-edit-invoice" :title="$t('components.modal-invoice.header-detail_invoice')" size="lg" hide-header-close>
            <div v-if="form">
                <b-form-group :label="$t('components.modal-invoice.label-client_name')">
                    <b-form-input :value="_.get(form, '_client.clientName', '')" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-email')">
                    <b-form-input :value="_.get(form, 'data.customer_email', '')" disabled />
                </b-form-group>

                <hr />

                <b-form-group :label="$t('components.modal-invoice.label-stripe-status')">
                    <b-form-input :value="_.get(form, 'data.status', '')" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-created_at')">
                    <b-form-input :value="getUnixFormat(form.data.created)" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-period_start_at')">
                    <b-form-input :value="getUnixFormat(form.data.period_start)" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-period_end_at')">
                    <b-form-input :value="getUnixFormat(form.data.period_end)" disabled />
                </b-form-group>

                <hr />

                <b-form-group :label="$t('components.modal-invoice.label-stripe-amount_due')">
                    <b-form-input :value="getPriceFormat(form.data.amount_due)" disabled />
                </b-form-group>
                <b-form-group :label="$t('components.modal-invoice.label-stripe-amount_paid')">
                    <b-form-input :value="getPriceFormat(form.data.amount_paid)" disabled />
                </b-form-group>
                <b-form-group :label="$t('components.modal-invoice.label-stripe-amount_remaining')">
                    <b-form-input :value="getPriceFormat(form.data.amount_remaining)" disabled />
                </b-form-group>

                <hr />

                <b-form-group :label="$t('components.modal-invoice.label-stripe-customer-id')">
                    <b-form-input :value="form.data.customer || ''" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-invoice-id')">
                    <b-form-input :value="form.data.id || ''" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-subscription-id')">
                    <b-form-input :value="_.get(form, 'data.lines.data[0].subscription', '')" disabled />
                </b-form-group>

                <b-form-group :label="$t('components.modal-invoice.label-stripe-price-id')">
                    <b-form-input :value="_.get(form, 'data.lines.data[0].price.id', '')" disabled />
                </b-form-group>
            </div>
            <!-- <div>
                <span
                    v-html="
                        JSON.stringify(form, null, 2)
                            .replace(/ /g, '&nbsp;')
                            .replace(/\n/g, '<br />')
                    "
                />
            </div> -->

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <!-- <b-button :disabled="processing || !isValidateEdit" variant="info" @click.prevent="editInvoice">
                    {{ $t('globals.button.save') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button> -->
            </template>
        </b-modal>

        <!-- <b-modal id="modal-cancel-invoice" :title="$t('globals.modal.warning')" hide-header-close>
            <p
                v-if="form._client"
                class="my-4"
                v-html="$t('components.modal-invoice.cancel-description-clientname', { clientName: `<b class=&quot;text-info&quot;>${_.get(form, '_client.clientName', '(no nane)')}</b>` })"
            />
            <p v-else class="my-4" v-html="$t('components.modal-invoice.cancel-description')" />
            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('components.modal-invoice.button-dont_cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="cancelInvoice">
                    {{ $t('components.modal-invoice.button-cancel_invoice') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal> -->
    </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            modal: false,
            processing: false,
            plans: this.$store.getters.getPlans().map((p) => {
                p.value = p.id;
                p.text = this.$t(`pages.pricing.plan-${p.id}`); // refresh i18n in the computed if it is different
                return p;
            }),
            durations: [
                { value: 'month', text: this.$t('pages.payment.checkbox-month') },
                { value: 'year', text: this.$t('pages.payment.checkbox-year') },
                { value: 'none', text: this.$t('pages.payment.checkbox-none') },
            ],
            form: null,
        };
    },

    methods: {
        getUnixFormat: (unix) => (unix ? dayjs(unix * 1000).format('YYYY.MM.DD HH:mm') : ''),

        // getPriceFormat: (amount) => (amount ? (amount / 100).toLocaleString('de-de', { style: 'currency', currency: 'EUR' }) : ''),
        getPriceFormat: (amount) => (_.isNumber(amount) ? `€${(amount / 100).toFixed(2)}` : ''),

        getDateFormat: (date) => dayjs(date).format('YYYY.MM.DD HH:mm'),
        // buttonDisableInvoice(invoice) {
        //     const form = { ...invoice };
        //     form.disabledAt = !form.disabledAt;

        //     invoice._processing = true;
        //     axios
        //         .put(`/api/superadmin/invoice/${form.clientID}`, form)
        //         .then(resp => {
        //             invoice._processing = false;
        //             this.$bvModal.hide('modal-invoice');
        //             this.$emit('editedInvoice', resp.data.invoice);
        //         })
        //         .catch(e => {
        //             invoice._processing = false;
        //             this.$bvToast.toast(e.toString(), {
        //                 title: this.$t('globals.toast.error'),
        //                 variant: 'danger',
        //                 solid: true
        //             });
        //         });
        // },

        buttonEditInvoice(invoice) {
            this.form = { ...invoice };
            console.log('invoice', invoice);
            // setTimeout(() => {
            this.$bvModal.show('modal-edit-invoice');
            // },0);
        },

        // buttonCancelInvoice(invoice) {
        //     this.form = { ...invoice };
        //     this.$bvModal.show('modal-cancel-invoice');
        // },
        // async cancelInvoice() {
        //     if (!_.get(this, 'form.invoiceID')) {
        //         return;
        //     }
        //     this.processing = true;
        //     try {
        //         const resp = await axios.delete(`/api${this.$store.getters.isMeRole('superadmin') ? `/superadmin` : '/stripe'}/invoice/${this.form.invoiceID}`);
        //         this.$bvModal.hide('modal-cancel-invoice');
        //         this.$emit('canceledInvoice', resp.data.invoice);
        //     } catch (e) {
        //         this.$bvToast.toast(e.toString(), {
        //             title: this.$t('globals.toast.error'),
        //             variant: 'danger',
        //             solid: true,
        //         });
        //     }
        //     this.processing = false;
        // },
    },
};
</script>
