<template>
    <div>
        <b-modal id="modal-client" :title="$t(`components.modal-client.header-${modal}_client`)" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-client.label-client')" label-for="client-name-input">
                <b-form-input id="client-name-input" v-model="form.clientName" trim :state="isValidateClientName" />
                <b-form-invalid-feedback :state="isValidateClientName">{{ $t('components.modal-client.validation-client') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-card bg-variant="light" :title="$t('components.modal-client.card-title')">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('components.modal-client.label-plan')" label-for="plan-input">
                            <b-form-select id="plan-input" v-model="form.plan" :options="plans" :state="isValidatePlan" />
                            <b-form-invalid-feedback :state="isValidatePlan">{{ $t('components.modal-client.validation-plan') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('components.modal-client.label-valid_until')" label-for="valid_until-input">
                            <b-form-input id="valid_until-input" v-model="form._validUntil" trim :state="isValidateValidUntil" placeholder="YYYY.MM.DD HH:mm" />
                            <b-form-invalid-feedback :state="isValidateValidUntil">{{ $t('components.modal-client.validation-valid_until') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <!-- <b-form-group :label="$t('components.modal-client.label-duration')" label-for="duration-input">
                            <b-form-select id="plan-input" v-model="form.duration" :options="durations" :state="isValidateDuration" />
                            <b-form-invalid-feedback :state="isValidateDuration">{{ $t('components.modal-client.validation-duration') }}</b-form-invalid-feedback>
                        </b-form-group> -->
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group :label="$t('components.modal-client.label-max_users') + getLabelPlanMax('users')" label-for="max-users-input">
                            <b-form-input id="max-users-input" v-model="form._maxUsers" trim :state="isValidateMaxUsers" />
                            <b-form-invalid-feedback :state="isValidateMaxUsers">{{ $t('components.modal-client.validation-max_users') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('components.modal-client.label-max_projects') + getLabelPlanMax('projects')" label-for="max-projects-input">
                            <b-form-input id="max-projects-input" v-model="form._maxProjects" trim :state="isValidateMaxProjects" />
                            <b-form-invalid-feedback :state="isValidateMaxProjects">{{ $t('components.modal-client.validation-max_projects') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('components.modal-client.label-max_sites') + getLabelPlanMax('sites')" label-for="max-sites-input">
                            <b-form-input id="max-sites-input" v-model="form._maxSites" trim :state="isValidateMaxSites" />
                            <b-form-invalid-feedback :state="isValidateMaxSites">{{ $t('components.modal-client.validation-max_sites') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>

                <b-button v-if="modal === 'add'" :disabled="processing || !isValidateSave" variant="info" @click.prevent="addClient">
                    {{ $t('globals.button.add') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>

                <b-button v-if="modal === 'edit'" :disabled="processing || !isValidateSave" variant="info" @click.prevent="editClient">
                    {{ $t('globals.button.save') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-delete-client" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="my-4" v-html="$t('components.modal-client.delete-description', { clientName: `<b class=&quot;text-info&quot;>${form.clientName}</b>` })" />

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="deleteClient">
                    {{ $t('globals.button.delete') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import t from '@/tool';

// const durations = [
//     { value: 'month', text: this.$t('pages.payment.checkbox-month') },
//     { value: 'year', text: this.$t('pages.payment.checkbox-year') },
//     { value: 'none', text: this.$t('pages.payment.checkbox-none') },
// ];

export default {
    data() {
        return {
            modal: false,
            processing: false,
            plans: this.$store.getters.getPlans().map((p) => {
                p.value = p.id;
                p.text = this.$t(`pages.pricing.plan-${p.id}-admin`); // refresh i18n in the computed if it is different
                return p;
            }),

            form: {
                clientName: '',
                plan: '',
                duration: '',
                _validUntil: '',
                _maxUsers: '0',
                _maxProjects: '0',
                _maxSites: '0',
            },
        };
    },
    computed: {
        // durations() {
        //   console.log('this.form.plan', this.form.plan)
        //   const enabledOnlyNone = ['free', 'free30d'].indexOf(this.form.plan) !== -1
        //     return [
        //         { value: 'month', text: this.$t('pages.payment.checkbox-month'), disabled: enabledOnlyNone },
        //         { value: 'year', text: this.$t('pages.payment.checkbox-year'), disabled: enabledOnlyNone },
        //         { value: 'none', text: this.$t('pages.payment.checkbox-none'), disabled: !enabledOnlyNone },
        //     ];
        // },

        isValidateMaxUsers() {
            const value = _.get(this.form, '_maxUsers') || '';
            return t.validationUnsignedInteger(value) && +value <= 999;
        },
        isValidateMaxProjects() {
            const value = _.get(this.form, '_maxProjects') || '';
            return t.validationUnsignedInteger(value) && +value <= 999;
        },
        isValidateMaxSites() {
            const value = _.get(this.form, '_maxSites') || '';
            return t.validationUnsignedInteger(value) && +value >= 5 && +value <= 999999;
        },
        isValidateValidUntil() {
            const validUntil = _.get(this.form, '_validUntil', '');
            return validUntil === '' ? null : t.validationDateTime(validUntil);
        },
        isValidatePlan() {
            return _.findIndex(this.plans, { id: this.form.plan }) !== -1;
        },
        // isValidateDuration() {
        //   const duration = _.find(this.durations, { value: this.form.duration })  ;
        //     return !!duration && !duration.disabled;
        // },
        isValidateClientName() {
            const len = _.size(_.get(this.form, 'clientName', ''));
            return 5 <= len && len <= 50;
        },
        isValidateSave() {
            return (
                this.isValidateClientName &&
                // this.isValidateDuration &&
                this.isValidatePlan &&
                (this.isValidateValidUntil || this.isValidateValidUntil === null) &&
                this.isValidateMaxUsers &&
                this.isValidateMaxProjects &&
                this.isValidateMaxSites
            );
        },
    },
    methods: {
        getLabelPlanMax(maxName) {
            const max = this.$store.getters.getPlanMax(this.form.plan, maxName);
            return max ? ` [ ${max} ]` : '';
        },

        buttonAddClient() {
            const plan = this.$store.getters.getPlan('free');
            console.log('plwan', plan);
            this.form = {
                clientID: null,
                clientName: '',
                plan: `${plan.id}`,
                // duration: 'month',
                _validUntil: dayjs().add(30, 'day').format('YYYY.MM.DD HH:mm'),
                _maxUsers: `${plan.maxUsers}`,
                _maxProjects: `${plan.maxProjects}`,
                _maxSites: `${plan.maxSites}`,
            };
            this.modal = 'add';
            console.log('this.form', this.form);
            console.log('this.modal', this.modal);
            this.$bvModal.show('modal-client');
        },
        async addClient() {
            if (!this.isValidateSave) {
                return;
            }
            this.form.validUntil = this.form._validUntil ? dayjs(this.form._validUntil) : null;
            this.form.maxUsers = +this.form._maxUsers;
            this.form.maxProjects = +this.form._maxProjects;
            this.form.maxSites = +this.form._maxSites;
            this.processing = true;
            try {
                const resp = await axios.post(`/api/superadmin/client`, this.form);
                this.$bvModal.hide('modal-client');
                this.$emit('addedClient', resp.data.client);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
            this.processing = false;
        },

        async buttonDisableClient(client) {
            const form = { ...client };
            form.disabledAt = !form.disabledAt;
            client._processing = true;
            try {
                const resp = await axios.put(`/api/superadmin/client/${form.clientID}`, form);
                this.$bvModal.hide('modal-client');
                this.$emit('editedClient', resp.data.client);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
            client._processing = false;
        },

        buttonEditClient(client) {
            this.form = {
                ...client,
                ...{
                    plan: _.get(client, 'meta.plan'),
                    // duration: _.get(client, 'meta.duration'),
                    _validUntil: _.get(client, 'meta.validUntil') ? dayjs(client.meta.validUntil).format('YYYY.MM.DD HH:mm') : '',
                    _maxUsers: `${_.get(client, 'meta.maxUsers', '')}`,
                    _maxProjects: `${_.get(client, 'meta.maxProjects', '')}`,
                    _maxSites: `${_.get(client, 'meta.maxSites', '')}`,
                },
            };
            this.modal = 'edit';
            this.$bvModal.show('modal-client');
        },
        async editClient() {
            if (!this.isValidateSave) {
                return;
            }
            this.form.validUntil = this.form._validUntil ? dayjs(this.form._validUntil) : null;
            this.form.maxUsers = +this.form._maxUsers;
            this.form.maxProjects = +this.form._maxProjects;
            this.form.maxSites = +this.form._maxSites;

            this.processing = true;
            try {
                const resp = await axios.put(`/api/superadmin/client/${this.form.clientID}`, this.form);
                this.$bvModal.hide('modal-client');
                this.$emit('editedClient', resp.data.client);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
            this.processing = false;
        },

        buttonDeleteClient(client) {
            this.form = { ...client };
            this.$bvModal.show('modal-delete-client');
        },
        async deleteClient() {
            if (!_.get(this, 'form.clientID')) {
                return;
            }
            this.processing = true;
            try {
                const resp = await axios.delete(`/api/superadmin/client/${this.form.clientID}`);
                this.$bvModal.hide('modal-delete-client');
                this.$emit('deletedClient', resp.data.client);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
            this.processing = false;
        },
    },
};
</script>
