<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.users.title') }}</h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <!-- <b-button v-if="$store.getters.isMeRole(['superadmin'])" variant="primary" class="ml-2" @click="$refs.modalUser.buttonAddUser">
                        <i class="fa fa-plus mr-2" />
                        {{ $t('pages.users.button-add_user') }}
                    </b-button> -->
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-pagination
                v-if="users.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredUsers.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredUsers" :fields="fields">
                <template v-slot:head(email)>
                    {{ $t('pages.users.th-email') }}
                </template>
                <template v-slot:cell(email)="u">
                    <i v-if="u.item.disabledAt" class="fa fa-ban mr-1 text-danger" />
                    {{ u.item.email }}
                    <span v-if="u.item.meta.requireVerify" v-b-tooltip :title="$t('pages.users.tooltip-noverification')" class="fa-stack pl-2">
                        <i class="fa fa-envelope fa-stack-1x" />
                        <i class="fa fa-ban fa-stack-2x text-danger" />
                    </span>
                </template>

                <template v-slot:head(role)>
                    {{ $t('pages.users.th-role') }}
                </template>
                <template v-slot:cell(role)="u">
                    <i v-if="u.item.meta.developer" v-b-tooltip :title="$t('pages.users.tooltip-developer')" class="fa fa-user-md mr-1 text-info" />
                    {{ u.item.role }}
                </template>

                <template v-slot:head(clientName)>
                    {{ $t('pages.clients.th-client_name') }}
                </template>
                <template v-slot:cell(clientName)="u">
                    <i class="fa fa fa-building-o mr-2" />
                    <i v-if="!u.item._client || u.item._client.disabledAt" class="fa fa-ban mr-2 text-danger" />
                    <router-link :to="`/superadmin/client/${u.item.clientID}/users`">
                        {{ u.item._client && u.item._client.clientName }}
                        <!--<i class="fa fa-arrow-circle-o-right ml-2" />-->
                    </router-link>
                </template>

                <!-- <template v-slot:head(actions)>
                    {{ $t('pages.users.th-actions') }}
                </template>
                <template v-slot:cell(actions)="u">
                    <div v-if="u.item.userID !== $store.getters.getMeUserID()" class="text-right">
                        <b-button class="ml-1" variant="text" @click="$refs.modalUser.buttonEditUser(u.item, $event.target)"><i class="fa fa-edit" /></b-button>
                        <b-button class="ml-1" variant="text" @click="$refs.modalUser.buttonDeleteUser(u.item, $event.target)"><i class="fa fa-trash" /></b-button>
                        <b-button class="ml-1" variant="text" :disabled="u.item._processing" @click="$refs.modalUser.buttonDisableUser(u.item, $event.target)">
                            <i v-if="u.item.disabledAt" class="fa fa-minus-square text-danger" />
                            <i v-if="!u.item.disabledAt" class="fa fa-check-square" />
                        </b-button>
                    </div>
                </template> -->
            </b-table>

            <b-pagination
                v-if="users.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredUsers.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

export default {
    data() {
        return {
            loading: false,
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                // { key: 'userID', sortable: true, sortDirection: 'asc', thStyle: 'width: 6rem' },
                { key: 'email', sortable: true, sortDirection: 'asc' },
                { key: 'role', sortable: true, sortDirection: 'asc', thStyle: 'width: 10rem' },
                { key: 'clientName', sortable: true, sortDirection: 'asc' }
            ],
            users: []
        };
    },
    computed: {
        filteredUsers() {
            const search = t.replaceSearch(this.search);
            return this.users.filter(u => `${u.email || ''}|${u._client?.clientName || ''}|${u.role || ''}`.match(search));
        }
    },
    created() {
        // if (this.$store.getters.isMeRole(['superadmin'])) {
        //     this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        // }
        this.loading = true;
        axios
            .get(`/api/superadmin/users`)
            .then(response => {
                this.users = [...response.data.users];
                this.users.forEach(u => {
                    u._client = _.find(response.data.clients, { clientID: u.clientID });
                });

                this.loading = false;
            })
            .catch(error => {
                console.log('catch', error);
                this.loading = false;
            });
    }
    // methods: {
    //     onAddedUser(user) {
    //         this.users.push({ ...user });
    //     },

    //     onEditedUser(user) {
    //         const index = _.findIndex(this.users, { userID: user.userID });
    //         this.$set(this.users, index, { ...this.users[index], ...user });
    //     },

    //     onDeletedUser(user) {
    //         this.$delete(this.users, _.findIndex(this.users, { userID: user.userID }));
    //     }
    // }
};
</script>
