<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.subscriptions.title') }}</h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <!-- <b-button v-if="$store.getters.isMeRole(['superadmin'])" variant="primary" class="ml-2" @click="$refs.modalUser.buttonAddUser">
                        <i class="fa fa-plus mr-2" />
                        {{ $t('pages.users.button-add_user') }}
                    </b-button> -->
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-pagination
                v-if="subscriptions.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredSubscriptions.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredSubscriptions" :fields="fields">
                <template #head(clientName)>
                    {{ $t('pages.clients.th-client_name') }}
                </template>
                <template #cell(clientName)="s">
                    <i class="fa fa fa-building-o mr-2" />
                    <i v-if="!s.item._client || s.item._client.disabledAt" class="fa fa-ban mr-2 text-danger" />
                    <router-link :to="`/superadmin/client/${s.item.clientID}/invoices`">
                        {{ s.item._client && s.item._client.clientName }}
                        <!--<i class="fa fa-arrow-circle-o-right ml-2" />-->
                    </router-link>
                </template>

                <template #head(email)>
                    {{ $t('pages.users.th-email') }}
                </template>
                <template #cell(email)="s">
                    <i v-if="s.item._user && s.item._user.disabledAt" class="fa fa-ban mr-1 text-danger" />
                    {{ s.item._user && s.item._user.email }}
                    <span v-if="s.item._user && s.item._user.meta.requireVerify" v-b-tooltip :title="$t('pages.users.tooltip-noverification')" class="fa-stack pl-2">
                        <i class="fa fa-envelope fa-stack-1x" />
                        <i class="fa fa-ban fa-stack-2x text-danger" />
                    </span>
                </template>

                <template #head(currentPeriodEndAt)>
                    {{ $t('components.modal-subscription.label-stripe-current_period') }}
                </template>
                <template #cell(currentPeriodEndAt)="s">
                    {{ (_.get(s.item, 'createdAt') && getDateFormat(s.item.createdAt)) || '' }}
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    {{ (_.get(s.item, 'currentPeriodEndAt') && getDateFormat(s.item.currentPeriodEndAt)) || '' }}
                </template>

                <template #head(status)>
                    {{ $t('Status') }}
                </template>
                <template #cell(status)="s">
                    {{ s.item.status }}
                    <div v-if="s.item.canceledAt">{{ $t('canceled at') }} {{ getDateFormat(s.item.canceledAt) }}</div>
                </template>

                <!-- <template #head(role)>
                    {{ $t('pages.users.th-role') }}
                </template>
                <template #cell(role)="u">
                    <i v-if="u.item.meta.developer" v-b-tooltip :title="$t('pages.users.tooltip-developer')" class="fa fa-user-md mr-1 text-info" />
                    {{ u.item.role }}
                </template> -->

                <template #head(actions)>
                    {{ $t('pages.users.th-actions') }}
                </template>
                <template #cell(actions)="s">
                    <b-button class="ml-1" variant="text" @click="$refs.modalSubscription.buttonEditSubscription(s.item)"><i class="fa fa-file-text-o fa-flip-vertical" /></b-button>
                    <!-- <b-button class="ml-1" variant="text" @click="$refs.modalSubscription.buttonDeleteSubscription(u.item, $event.target)"><i class="fa fa-trash" /></b-button> -->
                    <b-button v-if="!s.item.canceledAt" class="ml-1" variant="text" @click="$refs.modalSubscription.buttonCancelSubscription(s.item)"><i class="fa fa-times" /></b-button>
                    <!-- <b-button class="ml-1" variant="text" :disabled="u.item._processing" @click="$refs.modalSubscription.buttonDisableSubscription(u.item, $event.target)">
                        <i v-if="u.item.disabledAt" class="fa fa-minus-square text-danger" />
                        <i v-if="!u.item.disabledAt" class="fa fa-check-square" />
                    </b-button> -->
                </template>
            </b-table>

            <b-pagination
                v-if="subscriptions.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredSubscriptions.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <ModalSubscription ref="modalSubscription" @canceledSubscription="onCanceledSubscription" />
        <!-- @editedSubscription="onEditedSubscription"/> -->
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';

import ModalSubscription from '@/components/modalSubscription.vue';

export default {
    components: {
        ModalSubscription,
    },
    data() {
        return {
            loading: false,
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                // { key: 'clientName', sortable: true, sortDirection: 'asc' },
                // { key: 'userID', sortable: true, sortDirection: 'asc', thStyle: 'width: 6rem' },
                // { key: 'subscriptionID' },
                { key: 'clientName', sortable: true, sortDirection: 'asc' },
                { key: 'email', sortable: true, sortDirection: 'asc' },
                // { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                { key: 'status', sortable: true, sortDirection: 'asc' },
                { key: 'currentPeriodEndAt', sortable: true, sortDirection: 'asc' },
                // { key: 'data' }
            ],
            subscriptions: [],
        };
    },
    computed: {
        filteredSubscriptions() {
            // const search = t.replaceSearch(this.search);
            // return this.users.filter(u => `${u.email || ''}|${u._client?.clientName || ''}|${u.role || ''}`.match(search));
            return this.subscriptions;
        },
    },
    async created() {
        if (this.$store.getters.isMeRole(['superadmin'])) {
            this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        }
        this.loading = true;
        try {
            const response = await axios.get(`/api/superadmin/subscriptions`);
            this.subscriptions = [...response.data.subscriptions];
            _.each(response.data.subscriptions, (s) => {
                s._client = _.find(response.data.clients, { clientID: s.clientID });
                s._user = _.find(response.data.users, { userID: s.userID });
                console.log(s._user);
            });

            // console.log(this.subscriptions);
        } catch (error) {
            console.error('catch', error);
        }
        this.loading = false;
    },
    methods: {
        getDateFormat: (date) => dayjs(date).format('YYYY.MM.DD HH:mm'),
        // onAddedUser(user) {
        //     this.users.push({ ...user });
        // },

        // onEditedUser(user) {
        //     const index = _.findIndex(this.users, { userID: user.userID });
        //     this.$set(this.users, index, { ...this.users[index], ...user });
        // },

        // TODO: i think shoudl be delete
        onCanceledSubscription(subscription) {
            if (subscription.canceledAt) {
                const index = _.findIndex(this.subscriptions, { subscriptionID: subscription.subscriptionID });
                // this.$set(this.subscriptions, index, { ...this.subscriptions[index], status: 'canceled' });
                this.$delete(this.subscriptions, index);
            }
        },
    },
};
</script>
