<template>
    <div>
        <h1 class="mt-4">
            {{ $t('pages.client_invoices.title', { clientID: $route.params.clientID }) }} &raquo;
            <i18n path="pages.client_invoices.title_detail" tag="span">
                <template #clientName>
                    <i v-if="!_.has(client, 'clientName')" class="fa fa-spinner fa-spin fa-fw ml-2" />
                    <span v-else>{{ client.clientName }}</span>
                </template>
            </i18n>
        </h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <!-- <b-button v-if="$store.getters.isMeRole(['superadmin'])" variant="primary" class="ml-2" @click="$refs.modalUser.buttonAddUser">
                        <i class="fa fa-plus mr-2" />
                        {{ $t('pages.users.button-add_user') }}
                    </b-button> -->
                </b-col>
                <b-col cols="3">
                    <!-- <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group> -->
                </b-col>
            </b-row>

            <b-pagination
                v-if="invoices.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredInvoices.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredInvoices" :fields="fields">
                <template #head(clientName)>
                    {{ $t('pages.clients.th-client_name') }}
                </template>
                <template #cell(clientName)="s">
                    <i class="fa fa fa-building-o mr-2" />
                    <i v-if="!s.item._client || s.item._client.disabledAt" class="fa fa-ban mr-2 text-danger" />
                    <router-link :to="`/superadmin/client/${s.item.clientID}/users`">
                        {{ s.item._client && s.item._client.clientName }}
                        <!--<i class="fa fa-arrow-circle-o-right ml-2" />-->
                    </router-link>
                </template>

                <template #head(email)>
                    {{ $t('pages.invoices.th-stripe_email') }}
                </template>
                <template #cell(email)="s">
                    <!-- <i v-if="s.item._user && s.item._user.disabledAt" class="fa fa-ban mr-1 text-danger" /> -->
                    <!-- {{ s.item._user && s.item._user.email }} -->
                    {{ s.item.data.customer_email }}
                    <!-- <span v-if="s.item._user && s.item._user.meta.requireVerify" v-b-tooltip :title="$t('pages.users.tooltip-noverification')" class="fa-stack pl-2">
                        <i class="fa fa-envelope fa-stack-1x" />
                        <i class="fa fa-ban fa-stack-2x text-danger" />
                    </span> -->
                </template>

                <template #head(createdAt)>Date</template>
                <template #cell(createdAt)="s">
                    {{ (_.get(s.item, 'createdAt') && getDateFormat(s.item.createdAt)) || '' }}
                </template>

                <!-- <template #cell(invoice)="s">
                    <b-button v-if="s.item.hostedInvoiceUrl" class="ml-1" variant="text" target="_blank" :href="s.item.hostedInvoiceUrl">
                        <i class="fa fa-file-pdf-o" />
                    </b-button>
                </template> -->

                <template #head(status)>Status</template>
                <template #cell(status)="s">
                    {{ s.item.status }}
                </template>

                <template #head(plan)>Plan</template>
                <template #cell(plan)="s">
                    {{ $t(`pages.pricing.plan-${s.item.plan}`) }}
                </template>

                <template #head(amount)>Amount</template>
                <template #cell(amount)="s">
                    {{ getPriceFormat(s.item.data.total / 100) }}
                </template>

                <template #head(paid)>Paid</template>
                <template #cell(paid)="s">
                    {{ getPriceFormat(s.item.amountPaid) }}
                </template>

                <template #head(before)>Balance (before)</template>
                <template #cell(before)="s">
                    {{ s.item.data.starting_balance ? getPriceFormat(s.item.data.starting_balance / 100) : '' }}
                </template>

                <template #head(after)>Balance (after)</template>
                <template #cell(after)="s">
                    {{ s.item.data.ending_balance ? getPriceFormat(s.item.data.ending_balance / 100) : '' }}
                </template>

                <template #head(period)>Period</template>
                <template #cell(period)="s">
                    {{ getUnixFormat(s.item.data.period_start) }}
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    {{ s.item.data.period_start === s.item.data.period_end ? '...' : getUnixFormat(s.item.data.period_end) }}
                    <!-- <hr />

                    {{ getUnixFormat(s.item.data.lines.data[0].period.start) }}
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    {{ s.item.data.lines.data[0].period.start === s.item.data.lines.data[0].period.end ? '...' : getUnixFormat(s.item.data.lines.data[0].period.end) }}
                    <hr />

                    {{ getUnixFormat(Math.min(...s.item.data.lines.data.map(p => p.period.start))) }}
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    {{ Math.min(...s.item.data.lines.data.map(p => p.period.start)) === Math.max(...s.item.data.lines.data.map(p => p.period.end)) ? '...' : getUnixFormat(Math.max(...s.item.data.lines.data.map(p => p.period.end))) }} -->
                </template>

                <template #head(actions)>
                    {{ $t('pages.users.th-actions') }}
                </template>
                <template #cell(actions)="s">
                    <!-- <b-button class="ml-1" variant="text" @click="$refs.modalInvoice.buttonEditInvoice(s.item)"><i class="fa fa-edit" /></b-button> -->
                    <!-- <b-button class="ml-1" variant="text" @click="$refs.modalInvoice.buttonDeleteInvoice(u.item, $event.target)"><i class="fa fa-trash" /></b-button> -->
                    <!-- <b-button class="ml-1" variant="text" @click="$refs.modalInvoice.buttonCancelInvoice(s.item)"><i class="fa fa-times" /></b-button> -->
                    <!-- <b-button class="ml-1" variant="text" :disabled="u.item._processing" @click="$refs.modalInvoice.buttonDisableInvoice(u.item, $event.target)">
                        <i v-if="u.item.disabledAt" class="fa fa-minus-square text-danger" />
                        <i v-if="!u.item.disabledAt" class="fa fa-check-square" />


                    </b-button> -->

                    <b-button v-if="s.item.hostedInvoiceUrl" class="ml-1" variant="text" target="_blank" :href="s.item.hostedInvoiceUrl">
                        <i class="fa fa-file-pdf-o" v-b-tooltip="`Invoice`" />
                    </b-button>

                    <b-button v-if="s.item.invoiceID" class="ml-1" variant="text" @click="$refs.modalInvoice.buttonEditInvoice(s.item)">
                        <i class="fa fa-file-text-o fa-flip-vertical" />
                        <!-- <i :id="`tooltip-${s.item.invoiceID}`" v-b-tooltip.hover.left class="fa fa-address-card-o" />
                        <b-tooltip custom-class="tooltip-full" :target="`tooltip-${s.item.invoiceID}`" placement="bottom">
                            <b>invoiceID</b>
                            {{ s.item.invoiceID }}
                            <br />
                            <b>priceID:</b>
                            {{ s.item.priceID }}
                        </b-tooltip> -->
                    </b-button>
                </template>
            </b-table>

            <b-pagination
                v-if="invoices.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredInvoices.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <ModalInvoice ref="modalInvoice" />
    </div>
</template>

<style>
.tooltip-full .tooltip-inner {
    max-width: 500px;
}
</style>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';

import ModalInvoice from '@/components/modalInvoice.vue';

export default {
    components: {
        ModalInvoice,
    },
    data() {
        return {
            loading: false,
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                // { key: 'clientName', sortable: true, sortDirection: 'asc' },
                // { key: 'userID', sortable: true, sortDirection: 'asc', thStyle: 'width: 6rem' },
                // { key: 'clientName' },
                { key: 'email' },
                { key: 'createdAt', thStyle: 'width: 9rem' },
                { key: 'status', thStyle: 'width: 6rem' },
                { key: 'plan', thStyle: 'width: 9rem' },
                { key: 'before', thStyle: 'width: 6rem' },
                { key: 'amount', thStyle: 'width: 6rem' },
                { key: 'paid', thStyle: 'width: 6rem' },
                { key: 'after', thStyle: 'width: 6rem' },
                // { key: 'period', thStyle: 'width: 12rem' },
                // { key: 'invoice', thStyle: 'width: 9rem' },
                { key: 'actions', thStyle: 'width: 6rem' },
                // { key: 'data' }
            ],
            client: {},
            invoices: [],
        };
    },
    computed: {
        filteredInvoices() {
            // const search = t.replaceSearch(this.search);
            // return this.users.filter(u => `${u.email || ''}|${u._client?.clientName || ''}|${u.role || ''}`.match(search));
            return this.invoices;
        },
    },
    async created() {
        if (this.$store.getters.isMeRole(['superadmin'])) {
            this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        }
        this.loading = true;
        try {
            const response = await axios.get(`/api/superadmin/client/${this.$route.params.clientID}/invoices`);
            this.invoices = [...response.data.invoices];
            this.client = { ...response.data.client };
            _.each(response.data.invoices, (s) => {
                s._client = this.client;
            });
        } catch (error) {
            console.error('catch', error);
        }
        this.loading = false;
    },
    methods: {
        getUnixFormat: (unix) => (unix ? dayjs(unix * 1000).format('YYYY.MM.DD HH:mm') : ''),

        getDateFormat: (date) => dayjs(date).format('YYYY.MM.DD HH:mm'),
        getPriceFormat: (price) => `€${(+price).toFixed(2)}`.replace('€-', '-€'),
    },
};
</script>
